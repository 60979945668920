<template>
    <div class="row mb-3">
        <label for="height" class="col-md-1 col-form-label text-md-end">Visina (cm):</label>
        <div class="col-md-2">
            <input
                v-model.number="height"
                id="height"
                type="number"
                class="input form-control"
                name="height"
                required
            />
        </div>
        <label for="weight" class="col-md-1 col-form-label text-md-end">Težina (kg):</label>
        <div class="col-md-2">
            <input
                v-model.number="weight"
                id="weight"
                type="number"
                class="input form-control"
                name="weight"
                required
            />
        </div>
        <label for="BMI" class="col-md-1 col-form-label text-md-end">BMI:</label>
        <div class="col-md-2">
            <input
                :value="bmiValue"
                id="BMI"
                type="number"
                class="input form-control"
                name="BMI"
                readonly
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        patientWeight: Number,
        patientHeight: Number
    },
    data() {
        return {
            height: this.patientHeight,
            weight: this.patientWeight,
            BMI: 0
        }
    },

    computed: {
        bmiValue: function() {
            var calc = this.weight / (this.height / 100) / (this.height / 100);
            calc = calc.toFixed(2);

            return calc;
        }
    }
}
</script>
