import _ from 'lodash';
import { createApp } from 'vue';
import TherapyFormCreate from './components/TherapyFormCreate';
import TherapyFormEdit from './components/TherapyFormEdit';
import BMICalc from './components/BMICalc';
import { ZiggyVue } from 'ziggy';

window._ = _

const app = createApp({})
    .use(ZiggyVue)

app.component('therapy-form-create', TherapyFormCreate)
app.component('therapy-form-edit', TherapyFormEdit)
app.component('bmi-calculation', BMICalc)
app.mount('#app')